import React from "react";
import Select from "react-select";
import { colors } from "../utilities/themeVars";

const customStyles = {
  control: (provided, state) => {
    let borderColor = colors.gray4;
    if (state.isSelected || state.isFocused) {
      borderColor = colors.gray4;
    }
    return {
      ...provided,
      borderRadius: "0",
      borderWidth: `2px`,
      borderColor,

      "&:hover": {
        borderColor,
      },
    };
  },

  indicatorSeparator: () => null,

  option: (provided, state) => ({
    ...provided,
    background: state.isSelected ? colors.gray : colors.white,
    color: colors.text,
  }),

  dropdownIndicator: (provided, state) => {
    return { ...provided, color: colors.primary };
  },

  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = "opacity 300ms";
    const textTransform = "uppercase";
    const letterSpacing = "1px";
    const fontWeight = "bold";

    return {
      ...provided,
      opacity,
      transition,
      fontWeight,
      letterSpacing,
      textTransform,
    };
  },
};

// export default ({ options, onChange, defaultValue, setValue, value }) => (
//   <Select
//     // ref={ref => {
//     //   this.select = ref;
//     // }}
//     defaultValue={defaultValue || options[0]}
//     value={value}
//     label="Single select"
//     isSearchable={false}
//     options={options}
//     styles={customStyles}
//     onChange={val => onChange(val)}
//   />
// );

export default ({ options, onChange, defaultValue, setValue, value }) => {
  return (
    <Select
      // ref={ref => {
      //   this.select = ref;
      // }}
      defaultValue={defaultValue || options[0]}
      value={value}
      label="Single select"
      isSearchable={false}
      options={options}
      styles={customStyles}
      onChange={(val) => onChange(val)}
    />
  );
};
