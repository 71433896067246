import React from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { fontBold, textUpperCase } from "./Styled";
import { colors } from "../utilities/themeVars";
import arrow from "../images/arrow.svg";
import * as classes from "./HeadingWithSub.module.css";
import { Link } from "gatsby";

const Container = styled.div`
  margin: 10px 0;

  .main {
    margin-bottom: 0;
  }

  .small {
    font-size: 26px;
  }
  .subtitle {
    ${[textUpperCase, fontBold]};
    color: ${colors.primary};
    font-size: 12px;
    letter-spacing: 1px;
    margin-top: 6px;
  }
  .img {
    margin-left: 8px;
  }
  .headerLink {
    color: #000;
  }
  .withArrow {
    display: flex;
  }
`;

const HeadingWithSub = ({
  main,
  subTitle,
  h2 = false,
  backArrow = false,
  link,
}) => {
  return (
    <Container className={backArrow ? classes.withArrow : ""}>
      {link ? (
        <>
          {h2 ? (
            <Link to={link} className={"headerLink"}>
              <h2 className="main small">{main}</h2>
            </Link>
          ) : (
            <h1 className="main">{main}</h1>
          )}
          {subTitle && <div className={"subtitle"}>{subTitle}</div>}
          {backArrow && <img className={"img"} src={arrow} alt="back arrow" />}
        </>
      ) : (
        <>
          {h2 ? (
            <h2 className="main small">{main}</h2>
          ) : (
            <h1 className="main">{main}</h1>
          )}
          {subTitle && <div className={"subtitle"}>{subTitle}</div>}
          {backArrow && <img className={"img"} src={arrow} alt="back arrow" />}
        </>
      )}
    </Container>
  );
};

HeadingWithSub.propTypes = {
  main: PropTypes.string,
  subtitle: PropTypes.string,
};

export default HeadingWithSub;
