import { css } from "@emotion/react";
import { colors } from "../utilities/themeVars";

export const textUpperCase = css`
  text-transform: uppercase;
`;

export const colorPrimary = css`
  color: ${colors.primary};
`;

export const fontBold = css`
  font-weight: bold;
`;
